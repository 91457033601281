import {Container} from '#/app/components/Container.jsx';
import {Image} from '#/app/components/Image.jsx';

export const TrustedBy = (props: {size?: 'base' | 'xl'}) => {
  const size = props.size ?? 'base';
  return (
    <div className="bg-gray-100x w-full py-16">
      <Container className="flex flex-col space-y-8">
        <div className={`mx-auto flex w-full flex-col items-center justify-center space-y-10 ${size === 'xl' ? '' : 'md:w-8/12'}`}>
          <div className="text-center text-3xl font-semibold leading-tight text-gray-700 md:text-5xl">
            Top retailers trust
            {' '}
            <span className="font-semibold text-sky-500">Ufleet</span>
          </div>
          <Image alt="logos of metro obi devin ebag" src="/customers5.png" />
        </div>
      </Container>
    </div>
  );
};
